import './webpack-imports';
import { applyPolyfills } from './polyfills';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Inits
import vh from './inits/vh';
import scroll from './inits/scroll';
import lazy from './inits/lazy';
import slider from './inits/slider';
import mask from './inits/mask';
import modal from './inits/modal';
import ajaxForm from './inits/ajax-form';

// Animations
import fadeIn from './animations/fade-in';
import split from './animations/split';
import solutions from './animations/solutions';
import sticky from './animations/sticky';
import marquee from './animations/marquee';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    window.addEventListener('load', () => {
        gsap.registerPlugin(ScrollTrigger);
        window.scrollTo(0, 0);
        const loader = document.querySelector('.js-loader');
        if (loader) {
            loader.classList.add('is-hide');
        }

        // Inits
        vh.init();
        scroll.init();
        lazy.init();
        slider.init();
        mask.init();
        modal.init();
        ajaxForm.init();

        // Animations
        fadeIn.init();
        split.init();
        solutions.init();
        sticky.init();
        marquee.init();

        // Inits
        scroll.refresh();
        window.dispatchEvent(new Event('resize'));
    });
});

// Always at the end
module.hot?.accept();
